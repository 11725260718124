import React from 'react'
import './diposit.css';
import Close from '../assets/images/close.png';
import Check from '../assets/images/check-green.png';
import Info from '../assets/images/info.png';
import Support from '../assets/images/support.png';
import Chat from '../assets/images/chat.png';
import { useLocation, useNavigate } from 'react-router-dom'

const DepositPage = () => {
    const location = useLocation()
    const navigate = useNavigate()

    // console.log("-----depositPage",location);
    const { search } = useLocation();
  const searchParams =  new URLSearchParams(search);


  return (
    <div className='diposit-modal'>
        <header>
            <span>Add Money</span>
            <img src={Close} alt=""/>
        </header>

        <article>
            {/* <img src={Info} alt=""/> */}
            <img src={Check} alt=""/>
            <h4>Deposit <span>Confirmed</span></h4>
            <p>Your deposits was successfully added to Your 1337PROBET account</p>

            <div className='info-box'>
                <h5>Monday 8th August 09:22hrs</h5>

                <div className='info-box-item'>
                    <span>Amount</span>
                    <span>#100</span>
                </div>
                <div className='info-box-item'>
                    <span>Bonus money given</span>
                    <span>-</span>
                </div>
                <div className='info-box-item last'>
                    <span>Total balance</span>
                    <span>#356,68</span>
                </div>
            </div>

            <div className='btn-wrap'>
                <button>
                    <img src={Support} alt=""/>
                    Chat with us
                </button>
                <button>
                    <img src={Chat} alt=""/>
                    Write us
                </button>
            </div>

            <button className='button outline' onClick={()=>{navigate(`${location.state.url}?betSlip=true`)}}>Done</button>
            <button className='button' onClick={()=>{navigate(`${location.state.url}?betSlip=true`)}}>Done</button>
        </article>
    </div>
  )
}

export default DepositPage
