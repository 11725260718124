import "./App.css";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import dummyPage from "./assets/images/dummy-page.png";
import stickyImg from "./assets/images/stickyImg.png";
import { Route, Routes, useLocation } from "react-router-dom";
import SubHeader from "./SubHeader/SubHeader";
import Login from "./Login/Login";
import Widget from "./B2b-Widget/Widget";
import { useState } from "react";
import Register from "./Register/Register";
import { LoginContext } from "./Context/LoginContext";
import DepositPage from "./Deposit";
import Sports from "./B2b-Sports/Sports";
import CasinoWidget from "./B2b-Casino/Casino";

function App() {
  const location = useLocation();

  const [backTo, setBackTo] = useState("");
  const [logButton, setLogButton] = useState(false);
  const [activeTab, setActiveTab] = useState(() => {
      switch (location.pathname) {
        case "/esports":
          return "tab2";
        case "/casino":
          return "tab3";
        default:
          return "tab1";
      }
    }
  );

  const handleHeaderButtonClick = () => {
    alert("Header Button clicked");
  };
  const handleFooterButtonClick = () => {
    alert("Footer Button clicked");
  };

  return (
    <div className="App">
      <LoginContext.Provider value={{ logButton, setLogButton }}>
        <Header />
        <SubHeader activeTab={activeTab} setActiveTab={setActiveTab} />
        {/* <RouterProvider router={router} /> */}
        <Routes>
          {/* <Route
            path="/"
            element={
              <div className="dummy-page">
                <img src={dummyPage} alt="Page" />
                <img className="bottom-image" src={stickyImg} alt="Menu" />
              </div>
            }
          /> */}
          <Route path="/" element={<Sports />} />
          <Route path="/sports" element={<Sports />} />
          <Route path="/esports" element={<Widget setBackTo={setBackTo} />} />
          <Route path="/casino/*" element={<CasinoWidget />} />
          <Route
            path="/login"
            element={<Login setActiveTab={setActiveTab} backTo={backTo} />}
          />
          <Route
            path="/register"
            element={<Register setActiveTab={setActiveTab} backTo={backTo} />}
          />
          <Route path="/deposit" element={<DepositPage />} />
        </Routes>
      </LoginContext.Provider>

      <Footer />
    </div>
  );
}

export default App;
