import React from "react";
import './Footer.css';
import PaymentIcon from '../assets/images/payment_icons.png';
import logo from '../assets/images/logo.svg';

const Footer = () => {
    return (
        <div className="footer">
            <div className="logo">
                <img src={logo} alt="logo"/>
            </div>
            <div className="nav">
                <ul>
                    <li>Responsible Gambling</li>
                    <li>Contact Us</li>
                    <li>Help</li>
                </ul>
            </div>
            <div className="card">
                <img src={PaymentIcon} alt=""/>
            </div>
            <div className="bottom">
                <p>© 1337PROBET All rights reserved</p>

                <span>18+</span>
            </div>
        </div>
    )
}
export default Footer;
