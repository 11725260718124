import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios'

const CasinoWidget = () => {
	const navigate = useNavigate();

	   const getPlayerSession = async () => {
		const playerId = localStorage.getItem('widget-playerId');
	
		if (!playerId) {
		  console.error('Player ID not found in local storage')
		  return
		}
	
		const url = 'https://casino-test-api.leetent.co.uk/api/v1/player/session'
	
		const headers = {
		  'x-casino-auth-token': 'gvcb34gBsDgA352Dh6kobBhkl7L7490Dv2ZX2vxB',
		  'x-tenant': 'oyawin',
		  'Content-Type': 'application/json'
		}
	
		try {
		  const response = await axios.post(url, {playerId: playerId}, {headers})
		  const data = response.data
	
		  if (data.success) {
			console.log('successful-getPlayerSession:', data);
			const scriptElement = document.getElementById('casinoWidgetScript');
			const sid = data?.data?.sid || '';
			
			if (scriptElement && sid) {
				scriptElement.setAttribute('token', sid); 
				console.log('token:', sid);
			}
		  } else {
			console.error('error-getPlayerSession:', data?.message)
		  }
		} catch (error) {
		  console.error('failed-getPlayerSession:', error)
		}
	  }

	useEffect(() => {
		window.addEventListener("casinoActionEvent", function (event) {
			redirectUser(event);
		});
		
	    getPlayerSession();
	}, []);

	const redirectUser = (event) => {
		switch (event.detail.action) {
		case 'LOGIN':
			navigate('/login', { state: { url: event.detail.redirectUrl } });
			break;
		case 'REGISTER':
			navigate('/register', { state: { url: event.detail.redirectUrl } });
			break;
		default:
			break;
		}
	};

	return (
		<div>
		<div className="content-widget">
		  <div id="b2b-casino" style={{backgroundColor: 'var(--main-bg-color)'}}></div>
		</div>
	  </div>
	);
};

export default CasinoWidget;
